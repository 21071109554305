.interests {
	margin-bottom: 25px;
	width: 80vw;
}

.interests ul {
	width: fit-content;
	margin: 20px auto;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.tldr {
	border-radius: 15px;
	padding: 15px;
	margin: 20px 0;
	font-size: 20px;
	font-weight: 600;

	color: #5b6cf9;
	font-family: 'Nunito';
	letter-spacing: -.5px;
}

.interests li {
	text-transform: uppercase;
	/* background-color: rgb(229, 230, 240); */
	/* color: #63628d; */
	font-family: 'Roboto Condensed';
	/* color: #646ff7; */
	padding: 16px 5px;
	margin: 15px 10px;
	font-size: min(1.9vw, 20px);
	/* border-radius: 20px; */
	width: 220px;
	letter-spacing: 1.3px;
	display: inline;
	/* box-shadow: rgba(223, 223, 251, 1) 0px 7px 20px 0px; */
	text-decoration: underline rgba(171, 191, 245, 0.3);
}

.my-why,
.industries {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	width: fit-content;
	padding: 20px;
	border-radius: 15px;
	margin-top: 50px;
}

@media only screen and (max-width: 600px) {
	.industries,
	.interests {
		text-align: center;
		align-items: center;
	}

	.interests {
		width: 95vw;
	}

	.interests ul {
		padding: 0;
		margin: auto;
		margin-top: 20px;
		text-align: center;
		align-items: center;
	}

	.interests li {
		width: 200px;
		margin: 0 auto;
		padding: 8px;
		font-size: 15px;
	}
}
