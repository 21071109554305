.contact {
	list-style-type: none;
	font-family: 'Roboto Condensed', sans-serif;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	padding: 10px 0px;
	text-align: left;
}

.contact li {
	transition: opacity 300ms, transform 300ms;
	left: 0;
	font-size: 20px;
	height: 55px;
	width: 55px;
	filter: brightness(0);
	transition: 0.3s;

	margin: 40px -10px;
	border-radius: 300px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.contact li:hover {
	transform: scale(1.1);
	filter: none;
	box-shadow: 0px 8px 15px rgba(158, 167, 250, 0.5);
}

#address {
	left: -40px;
}

#phone {
	left: -20px;
}

#address,
#phone {
	position: absolute;
	top: 65px;
	width: 50vw;
	font-size: 18px;
}

.icon-div {
	display: inline-block;
	position: relative;
}

.contact-icon {
	height: 25px;
	position: absolute;
	left: 16px;
	top: -3px;
}

.hidden {
	position: absolute;
	display: inline-block;
	margin-left: 15px;
}

.hidden-enter {
	opacity: 0;
	transform: scale(0.9);
}

.hidden-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}

.hidden-exit {
	opacity: 1;
}
.hidden-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}

@media only screen and (max-width: 600px) {
	.contact {
		transition-duration: 300ms;
		padding: 0;
		margin: auto;
		padding-bottom: 50px;
	}

	.contact li {
		position: relative;
		height: 40px;
		width: 40px;
		margin: 20px 0;
		text-align: left;
		padding-right: 10px;
	}

	.hidden {
		position: relative;
		display: block;
		margin-left: 5px;
	}

	.contact-icon {
		height: 22px;
		padding: 0;
		position: absolute;
	}

	.contact a {
		position: absolute;
		left: -5px;
		top: -5px;

		text-align: left;
		/* width: 100px; */
	}

	#address,
	#phone {
		position: absolute;
		width: 20vh;
		font-size: 12px;
		top: 50px;
	}

	#address {
		left: -25px;
		margin-left: 0;
		text-align: left;
	}

	#phone {
		left: -40px;
		text-align: right;
	}
}
