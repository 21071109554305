.projects {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	width: fit-content;
	padding: 20px;
	border-radius: 15px;
	margin-top: 20px;
}

.projects img {
	width: 250px;
	margin: auto;
}

.projects ul {
	margin-top: 20px;
	text-align: left;
	list-style-type: none;
}
.projects li {
	margin-top: 7px;
}

.curr-project a {
	color: #5b6cf9;
}

.projects-icon {
	font-size: 8vh;
}

.project-tabs {
	font-family: 'Roboto Condensed';
	text-transform: uppercase;
	letter-spacing: 1.3px;
}

.project-tabs a {
	color: rgba(112, 128, 144);
	transition: 0.3s;
}

.project-tabs a:hover {
	color: #646ff7;
}

.project-tab .row,
.project-tab .row .col {
	background-color: rgba(255, 255, 255, 1);
}

.project-bg {
	background-color: rgba(255, 255, 255, 1);
}

.card {
	border: none;
}
.project-title {
	font-family: 'Nunito';
	font-weight: 300;
	font-size: 25px;
	margin-top: 20px;
	text-decoration: underline rgba(171, 191, 245, 0.3) 2px;

	margin-bottom: 20px;
}

.bolder {
	font-weight: 800;

	margin-right: 5px;
}

/* links CSS- https://codepen.io/brenden/pen/RNZXqx */
.project-links {
	text-align: center;
	margin: auto;
	margin-top: 30px;
	font-size: 28px;
}

.project-links a,
.project-links a:hover,
.project-links a:visited,
.project-links a:active {
	text-align: center;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	position: relative;
	transition: 0.5s ease;
	text-decoration: none;
	line-height: 25px;
	display: block;
	margin: 20px 0 !important;
	color: #000;
	font-family: 'Roboto Condensed';
	text-transform: uppercase;
	letter-spacing: 1.3px;
	font-size: 20px;
}

.project-links a:hover {
	color: #646ff7;
	letter-spacing: 1.7px;
}

.project-links a:after {
	content: "";
	transition: 0.5s all ease;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	bottom: -0.25em;
	height: 2px;
	width: 0;
	background: rgba(171, 191, 245, 0.3);
	/* background: #646ff7; */
}
.project-links a:hover:after {
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.project-bg {
		width: 99vw;
	}
	.projects {
		margin-top: 20px;
		padding-left: 0;
		padding-bottom: 50px;
		width: 99%;
	}

	.project-title {
		font-size: 25px;
	}

	.project-tabs {
		flex-wrap: nowrap;
	}

	.project-tabs a {
		font-size: 12px;
		text-align: center;
	}
	.project-links a {
		font-size: 14px;
		width: 40vw;
	}

	.project-links a:hover {
		font-size: 11px;
	}

	.skills ul {
		width: 40vw;
	}
}
