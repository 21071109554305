.skills {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	width: fit-content;
	padding: 20px;
	border-radius: 15px;
}

.skills h2 {
	margin: 10px 0;
}

.skills ul {
	margin-top: 20px;
	text-align: left;
	list-style-type: none;
}
.skills li {
	margin-top: 7px;
}

.skills-icon {
	height: 8vh;
}

@media only screen and (max-width: 600px) {
	.skills {
		margin-top: 20px;
		padding: 0;
		width: 95vw;
	}

	.skills ul {
		width: 40vw;
	}
}
