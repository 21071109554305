/* font-family: 'Julius Sans One', sans-serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Roboto Condensed', sans-serif; */

body {
	margin-bottom: 60px;

	background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.all-body {
	background-image: linear-gradient(120deg, #fdfbfb 0%, #f7f9fa 100%);
	margin: 20px auto;
	border-radius: 20px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	padding-bottom: 50px;
	font-size: 17px;
	max-width: 1500px;
}

.header {
	align-items: center;
	text-align: left;
	max-width: 99vw;
	margin-bottom: 75px;
	padding: 0 10px;
	margin-left: 30px;
}

.title {
	font-size: min(4.75vw, 75px);

	letter-spacing: -4px;
}

.title-contact {
	padding-top: 80px;
}

.subtitle,
.slogan,
.title {
	font-family: 'Nunito';
	font-weight: 500;
}

.slogan {
	font-size: min(3vw, 75px);
	letter-spacing: -2px;
}

/* text morph */

.wrapper span {
	display: inline-flex;
	margin: 0;
	vertical-align: top;
	max-width: 100%;
	width: fit-content;
}

.words {
	color: #5b6cf9;
	animation: word 15s infinite ease-in-out;
	position: absolute;
	display: flex;
	max-width: 100%;
	padding-left: 10px;
}

.title0 {
	animation-delay: -15s;
}

.title1 {
	animation-delay: -10s;
}

.title2 {
	animation-delay: -5s;
}

@keyframes word {
	0%,
	5%,
	100% {
		filter: blur(0px);
		opacity: 1;
	}
	20%,
	80% {
		/* filter: blur(15px); */
		opacity: 0;
	}
}

.subtitle {
	margin-top: 20px;
	letter-spacing: -2px;
	position: relative;
	text-align: center;
	font-size: 40px;
}

.pg {
	margin-top: 10px;
}

.about-me {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	width: fit-content;
	padding: 20px 80px 75px;
	border-radius: 15px;
	margin-top: 50px;
}

.headshot img {
	height: 375px;
	position: absolute;
	margin-top: 19px;
	margin-left: 40px;
	z-index: 2;
}

.headshot {
	left: 20px;
	right: auto;
	top: 0;
}

.headshot-container {
	position: relative;
	height: 400px;
	width: 400px;
	margin: auto;

	margin-top: 80px;
	padding-top: 20px;
}

.background-headshot2,
.background-headshot3,
.background-headshot4 {
	position: absolute;
	border-radius: 500px;
	z-index: 0;
}

.background-headshot1 {
	position: relative;
	z-index: 2;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 15px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgba(255, 255, 255, 0.3);
	height: 375px;
	width: 340px;
	margin: auto;
}
.background-headshot2 {
	background-color: #8bc6ec;
	background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);

	top: -5px;
	height: 200px;
	width: 200px;
}

.background-headshot4 {
	background-color: #8ec5fc;
	background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);

	right: 10px;
	bottom: -15px;
	height: 150px;
	width: 150px;
}

.dogs {
	margin-top: 30px;
	font-weight: 700;
	height: 150px;
	width: 100%;
	position: relative;
}
.dogs img {
	height: 120px;
	filter: grayscale(100%);
	margin: 0 20px;
	transition: opacity 300ms, transform 300ms;
}

.name {
	font-size: 13px;
}

.hank img:hover {
	filter: none;
	transform: rotate(20deg);
}
.hopper img:hover {
	filter: none;
	transform: rotate(-20deg);
}

.loading {
	position: relative;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	color: #8abae5;
	margin: 50px 8px;
}
.loading-dots {
	text-align: center;
}

#spin2 {
	animation-delay: 0.1s;
	color: #8aabdf;
}
#spin3 {
	animation-delay: 0.2s;
	color: #9599e2;
}

/* https://codepen.io/ssk84/pen/LYpzYZX */
#wrap {
	text-align: center;
	text-transform: uppercase;
	position: absolute;
	bottom: 30px;
	left: 60px;
}

.hank:hover,
.hopper:hover {
	color: #5b6cf9;
}

.hank,
.hopper {
	color: rgba(0, 0, 0, 0);
	line-height: 17px;
	font-size: 13px;
	position: relative;
	display: inline;
	transition: color 300ms, transform 300ms;
	height: 100px;
}

#hopper-text,
#hank-text {
	position: absolute;
	top: 75px;
	letter-spacing: -0.25px;
	font-family: 'Nunito';
	font-weight: 500;
	width: 200px;
	text-align: center;
}

#hopper-text:hover,
#hank-text:hover {
	color: rgba(0, 0, 0, 0);
}
#hopper-text {
	right: 20%;
}
#hank-text {
	left: 0;
}
#hopper:hover,
#hank:hover {
	display: inline;
	margin-left: 0;
	z-index: 1;
	height: 100px;
	transition: opacity 300ms, transform 300ms;
}

@media only screen and (max-width: 600px) {
	body,
	.all-body {
		padding: 0;
		padding-bottom: 50px;
		width: 100vw;
		font-size: 14px;
		margin: 0;
	}
	.title {
		font-family: 'Nunito';
		font-size: 35px;
		font-weight: 400;
		/* text-align: center; */
		margin-left: 0;
	}

	.title-contact {
		padding-top: 20px;
	}

	.slogan {
		font-size: 22px;
	}

	.subtitle,
	.slogan {
		font-weight: 400;
		font-size: 28px;
		margin-left: 0px;
		width: 100%;
	}

	.about-me {
		margin: 20px auto;
		width: 95vw;
		padding-left: 10px;
		padding-right: 10px;
	}

	.headshot {
		margin-top: 25px;
		top: 4px;
		left: -10px;
		margin-left: auto;
	}

	.headshot-container {
		position: relative;

		height: 200px;
		width: 200px;
		margin-left: 22.5vw;
		margin-top: 0;
		padding-top: 0;
	}

	.background-headshot1 {
		height: 200px;
		width: 150px;
	}

	.background-headshot2 {
		height: 110px;
		width: 110px;
		top: -10px;
	}
	.background-headshot4 {
		height: 80px;
		width: 80px;
		bottom: -10px;
		right: 15px;
	}

	.headshot img {
		height: 175px;
	}

	.header {
		margin: 0;
	}

	.job-title {
		font-size: 16px;
		padding: 7px;
		margin: 3px auto;
	}
	.col {
		width: 99vw;
	}

	.hank,
	.hopper {
		font-size: 12px;
		height: 150px;
	}
	.hank img,
	.hopper img {
		height: 85px;
	}

	#hopper-text,
	#hank-text {
		width: 45vw;
	}
}
